<template>
  <div class="container mt-5 px-2">
    <article class="message is-danger" v-if="!token">
      <div class="message-body">
        You have not signed in yet. Please go back to <a href="/">home page</a> to sign in first.
      </div>
    </article>
    <div v-if="token">
      <div>
        <span class="is-pulled-right">
          <a class="button mr-2" @click="containerCostModal.opened = true">Update container prices</a>
          <a class="button mr-2" @click="exportThisPage">Export</a>
        </span>
        
        <h1 class="title">Products Landed Costs</h1>

        <div class="mt-3">
          <div v-if="waiting">
            <span class="icon is-medium is-size-4">
              <i class="fas fa-spinner fa-pulse"></i>
            </span>
          </div>
          <div v-else>
            <div class="columns mt-4">
              <div class="column field mb-0 pb-0">
                <p class="control has-icons-left">
                  <span class="select">
                    <select v-model="filter">
                      <option v-for="(c, i) in classOptions" :key="'product-class-option' + i" :value="c">
                        {{c}} ({{counts[c]}})
                      </option>
                    </select>
                  </span>
                  <span class="icon is-small is-left">
                    <i class="fas fa-filter"></i>
                  </span>
                </p>
              </div>
              <div class="column field  mb-0 pb-0">
                <p class="control has-icons-left">
                  <input class="input" type="text" placeholder="Search" v-model="search">
                  <span class="icon is-small is-left">
                    <i class="fas fa-search"></i>
                  </span>
                </p>
              </div>
            </div>

            <div class="table-container">
            <table class="table is-fullwidth is-hoverable is-striped">
              <thead>
                <th class="is-clickable" @click="changeSortOption('id')">
                  <span>Id</span>
                  <span class="icon" v-if="sortOption.field == 'id'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable" @click="changeSortOption('itemName')">
                  <span>Product</span>
                  <span class="icon" v-if="sortOption.field == 'itemName'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable" @click="changeSortOption('idIndex')">
                  <span>Id Index</span>
                  <span class="icon" v-if="sortOption.field == 'idIndex'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable" @click="changeSortOption('usInventory')">
                  <span>US Inventory</span>
                  <span class="icon" v-if="sortOption.field == 'usInventory'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">CA, NY, TX, ON</span>
                </th>
                <th class="is-clickable" @click="changeSortOption('saleSpeed')">
                  <span>US Sale Speed</span>
                  <span class="icon" v-if="sortOption.field == 'saleSpeed'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">Canada</span>
                </th>
                <th class="is-clickable" @click="changeSortOption('usLandedCost')">
                  <span>US Landed Cost</span>
                  <span class="icon" v-if="sortOption.field == 'usLandedCost'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">CA, NY, TX, ON</span>
                </th>
                <th class="is-clickable" @click="changeSortOption('purchasePrice')">
                  <span>Purchase Price</span>
                  <span class="icon" v-if="sortOption.field == 'purchasePrice'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">Eric</span>
                </th>
                <th class="is-clickable" @click="changeSortOption('futureLandedCost.futureLandedCost.US')">
                  <span>Future Landed Cost</span>
                  <span class="icon" v-if="sortOption.field == 'futureLandedCost.futureLandedCost.US'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">CA, NY, TX, ON</span>
                </th>
                <th class="is-clickable" @click="changeSortOption('currentWayfairGp')">
                  <span>Wayfair GP</span>
                  <span class="icon" v-if="sortOption.field == 'currentWayfairGp'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">Revenue</span>
                </th>
                <th class="is-clickable" @click="changeSortOption('currentWayfairCanadaGp')">
                  <span>Wayfair Canada GP</span>
                  <span class="icon" v-if="sortOption.field == 'currentWayfairCanadaGp'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">Revenue</span>
                </th>
                <th class="is-clickable" @click="changeSortOption('currentHomeDepotGp')">
                  <span>Home Depot GP</span>
                  <span class="icon" v-if="sortOption.field == 'currentHomeDepotGp'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">Revenue</span>
                </th>
                <th class="is-clickable" @click="changeSortOption('currentHomeDepotCanadaGp')">
                  <span>Home Depot Canada GP</span>
                  <span class="icon" v-if="sortOption.field == 'currentHomeDepotCanadaGp'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">Revenue</span>
                </th>
              </thead>
              <tbody>
                <tr v-for="(p, i) in showingProducts" :key="'product-' + i">
                  <td>{{p.id}}</td>
                  <td>
                    <span>{{p.itemName}}</span><br/>
                    <span class="is-size-7 has-text-grey">{{p.className}}</span>
                  </td>
                  <td>
                    <div v-if="p.combo">
                      <div v-for="k in p.comboKeys" :key="'combo-' + k">
                        <router-link :to="'/product-inventory/' + k">{{idIndexMap[k]? idIndexMap[k].nsName : k}}</router-link>: {{p.combo[k]}}
                      </div>
                    </div>
                    <div v-else>
                      <div>{{p.nsName}}</div>
                      <div>
                        <router-link :to="'/product-inventory/' + p.idIndex">{{p.idIndex}}</router-link>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span>{{p.usInventory}}</span><br/>
                    <span class="is-size-7 has-text-grey">
                      <span>{{p.caInventory + ', ' + p.nyInventory + ', ' + p.txInventory}}</span>
                      <span v-if="p.onInventory != null">{{', ' + p.onInventory}}</span>
                    </span>
                  </td>
                  <td>
                    <span>{{p.saleSpeed}}</span><br/>
                    <span class="is-size-7 has-text-grey">
                      <span>{{p.saleSpeedCanada}}</span>
                    </span>
                  </td>
                  <td>
                    <span>{{p.usLandedCost}}</span><br/>
                    <span class="is-size-7 has-text-grey">
                      <span>{{p.caLandedCost + ', ' + p.nyLandedCost + ', ' + p.txLandedCost + ', ' + p.onLandedCost}}</span>
                    </span>
                  </td>
                  <td>
                    <span>{{p.purchasePrice}}</span><br/>
                    <span class="is-size-7 has-text-grey">
                      <span>{{p.ericPurchasePrice}}</span>
                    </span>
                  </td>
                  <td>
                    <span v-if="p.combo">
                      <span>{{p.futureLandedCost.futureLandedCost.US}}</span><br/>
                      <span class="is-size-7 has-text-grey">
                        <span>{{p.futureLandedCost.futureLandedCost.CA + ', ' + p.futureLandedCost.futureLandedCost.NY + ', ' + p.futureLandedCost.futureLandedCost.TX}}</span>
                        <span v-if="p.futureLandedCost.futureLandedCost.ON">{{', ' + p.futureLandedCost.futureLandedCost.ON}}</span>
                      </span>
                    </span>
                    <span v-else>
                      <span>{{p.futureLandedCost.futureLandedCost.US}}</span><br/>
                      <span class="is-size-7 has-text-grey is-clickable" @click="openLandedCostModal(p)">
                        <span v-if="p.futureLandedCost.computed">
                          {{p.futureLandedCost.futureLandedCost.CA + ', ' + p.futureLandedCost.futureLandedCost.NY + ', ' + p.futureLandedCost.futureLandedCost.TX + ', ' + p.futureLandedCost.futureLandedCost.ON}}
                        </span>
                        <span v-else>Not set</span>
                      </span>
                    </span>
                  </td>
                  <td>
                    <span>{{p.currentWayfairGp ? (p.currentWayfairGp + '%') : ''}}</span>
                    <span>{{p.futureWayfairGp ? (', ' + p.futureWayfairGp + '%') : ''}}</span>
                    <br/>
                    <span class="is-size-7 has-text-grey">
                      <span>{{p.wayfairRevenue}}</span>
                    </span>
                  </td>
                  <td>
                    <span>{{p.currentWayfairCanadaGp ? (p.currentWayfairCanadaGp + '%') : ''}}</span>
                    <span>{{p.futureWayfairCanadaGp ? (', ' + p.futureWayfairCanadaGp + '%') : ''}}</span>
                    <br/>
                    <span class="is-size-7 has-text-grey" v-if="p.wayfairCanadaRevenueUsd">
                      <span>{{p.wayfairCanadaRevenueUsd + ' (' + p.wayfairCanadaRevenueCad + 'CAD)'}}</span>
                    </span>
                  </td>
                  <td>
                    <span>{{p.currentHomeDepotGp ? (p.currentHomeDepotGp + '%') : ''}}</span>
                    <span>{{p.futureHomeDepotGp ? (', ' + p.futureHomeDepotGp + '%') : ''}}</span>
                    <br/>
                    <span class="is-size-7 has-text-grey">
                      <span>{{p.homeDepotRevenue}}</span>
                    </span>
                  </td>
                  <td>
                    <span>{{p.currentHomeDepotCanadaGp ? (p.currentHomeDepotCanadaGp + '%') : ''}}</span>
                    <span>{{p.futureHomeDepotCanadaGp ? (', ' + p.futureHomeDepotCanadaGp + '%') : ''}}</span>
                    <br/>
                    <span class="is-size-7 has-text-grey" v-if="p.homeDepotCanadaRevenueUsd">
                      <span>{{p.homeDepotCanadaRevenueUsd + ' (' + p.homeDepotCanadaRevenueCad + 'CAD)'}}</span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
      
      
      <div v-if="error" class="notification is-danger is-light">
        <button class="delete" @click="error=''"></button>
        {{error}}
      </div>
    </div>

    <landed-cost-params-modal :opened="landedCostParamsModal.opened" :product="landedCostParamsModal.product"
      @closeLandedCostParamsModal="closeLandedCostModal">
    </landed-cost-params-modal>

    <container-cost-modal :opened="containerCostModal.opened" :products="products"
      @closeContainerCostModal="closeContainerCostModal">
    </container-cost-modal>
  </div>
</template>
  
<script>
import LandedCostParamsModal from '@/components/modals/LandedCostParamsModal'
import ContainerCostModal from '@/components/modals/ContainerCostModal'
import dateFormat from 'dateformat'
import { saveAs } from 'file-saver'

function getFieldValue (obj, path) {
  var ss = path.split('.')
  var value = obj[ss[0]]
  var i = 1
  while (i < ss.length && value) {
    value = value[ss[i]]
    i += 1
  }
  return value
}

export default {
  name: 'ProductsLandedCosts',
  components: {
    LandedCostParamsModal,
    ContainerCostModal
  },
  data () {
    return {
      error: '',
      waiting: false,
      search: '',
      filter: 'All',
      products: [],
      idIndexMap: {},
      landedCostParamsModal: {
        opened: false,
        product: null,
      },
      containerCostModal: {
        opened: false,
      },
      classOptions: [],
      counts: {},
      search: '',
      sortOption: { field: 'id', asc: true },
      feeRates: {},
      usdToCad: 1.36,
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
    token () {
      return this.$store.state.user.token
    },
    showingProducts () {
      var vm = this
      var filteredProducts = this.products.filter(p => {
        if (vm.filter == 'All') {
          return true
        }
        return p.className == vm.filter
      }).filter(p => {
        var search = vm.search.toLowerCase()
        return p.idIndex.toLowerCase().includes(search)
          || p.itemName.toLowerCase().includes(search)
          || p.className.toLowerCase().includes(search)
      })

      var sort = vm.sortOption
      var sortedProducts = filteredProducts.sort((a, b) => {
        var va = getFieldValue(a, sort.field)
        var vb = getFieldValue(b, sort.field)
        if (!va || !vb) {
          if (va) {
            return sort.asc ? 1 : -1
          }
          if (vb) {
            return sort.asc ? -1 : 1
          }
          return 0
        }
        if (sort.field == 'purchasePrice' || sort.field == 'id' || sort.field == 'usInventory' || sort.field == 'usLandedCost'
            || sort.field == 'futureLandedCost.futureLandedCost.US' || sort.field == 'wayfairRevenue' || sort.field == 'currentWayfairGp' || sort.field == 'currentWayfairCanadaGp'
            || sort.field == 'homeDepotRevenue' || sort.field == 'currentHomeDepotGp' || sort.field == 'currentHomeDepotCanadaGp' || sort.field == 'saleSpeed') {
          return sort.asc ? va - vb : vb - va
        }
        return sort.asc ? va.localeCompare(vb) : vb.localeCompare(va)
      })
      return sortedProducts
    },
  },
  watch: {
    filter: function (val) {
    },
  },
  methods: {
    getProducts () {
      this.waiting = true
      this.$http.get(this.server + '/myapp/get-product-with-landed-costs/').then(resp => {
        this.usdToCad = resp.body.usdToCad
        this.feeRates = this.computeFeeRates(resp.body.channels)
        var filteredProducts = resp.body.products.filter(p => this.filterByClassName(p.product.productClass))
        var products = filteredProducts.map(p => this.makeProductData(p))
        this.products = this.makeupProducts(products)
        var classNames = new Set()
        var counts = {All: 0}
        var idIndexMap = {}
        this.products.forEach(p => {
          classNames.add(p.className)
          if (!counts[p.className]) {
            counts[p.className] = 0
          }
          counts[p.className] += 1
          counts.All += 1
          idIndexMap[p.idIndex] = p
        })
        this.classOptions = [...classNames]
        this.classOptions.sort((a, b) => a.localeCompare(b))
        this.classOptions.unshift('All')
        this.counts = counts
        this.idIndexMap = idIndexMap
        this.waiting = false
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
    changeSortOption (field) {
      this.sortOption = {
        field: field,
        asc: field == this.sortOption.field ? !this.sortOption.asc : true
      }
    },
    filterByClassName (className) {
      return true
      if (!className || !className.trim()) {
        return false
      }
      if (className.startsWith('Consignment') || className.startsWith('[') || className.endsWith('(discontinued)')) {
        return false
      }
      if (['OVERFLOW', 'Overflow', 'Pallet', 'REPLACEMENT', '- No Class -', ].includes(className)) {
        return false
      }
      return true
    },
    makeProductData (p) {
      if (!p.inventory) {
        return {
          id: p.product.id,
          combo: JSON.parse(p.product.nsSku),
          comboKeys: Object.keys(JSON.parse(p.product.nsSku)),
          itemName: p.product.model,
          nsName: p.product.nsName,
          className: p.product.productClass,
          idIndex: '',
          futureLandedCost: {},
          product: p.product,
        }
      }
      var i = p.inventory
      var total = (i.caInventory || 0) * (i.caLandedCost || 0) + (i.nyInventory || 0) * (i.nyLandedCost || 0) + (i.txInventory || 0) * (i.txLandedCost || 0)
      var count = (i.caLandedCost ? (i.caInventory || 0) : 0) + (i.nyLandedCost ? (i.nyInventory || 0) : 0) +(i.txLandedCost ? (i.txInventory || 0) : 0)
      if (count == 0) {
        total =  (i.caLandedCost || 0) + (i.nyLandedCost || 0) + (i.txLandedCost || 0)
        count = (i.caLandedCost ? 1 : 0) + (i.nyLandedCost ? 1 : 0) + (i.txLandedCost ? 1 : 0)
      }
      var usLandedCost = count ? (total / count) : 0
      var onLandedCost = i.onLandedCost || 0
      var landedCostParams = JSON.parse(p.product.landedCostParams)
      var futureLandedCost = this.computeFutureLandedCost(landedCostParams)
      var wayfairRevenue = this.computeRevenue(p.product, 2)
      var currentWayfairGp = this.computeGp(wayfairRevenue, usLandedCost, this.feeRates[2])
      var futureWayfairGp = this.computeGp(wayfairRevenue, futureLandedCost.futureLandedCost.US, this.feeRates[2])
      var wayfairCanadaRevenueCad = this.computeRevenue(p.product, 7)
      var wayfairCanadaRevenueUsd = wayfairCanadaRevenueCad / this.usdToCad
      var currentWayfairCanadaGp = this.computeGp(wayfairCanadaRevenueUsd, onLandedCost, this.feeRates[7])
      var futureWayfairCanadaGp = this.computeGp(wayfairCanadaRevenueUsd, futureLandedCost.futureLandedCost.ON, this.feeRates[7])
      var homeDepotRevenue = this.computeRevenue(p.product, 1)
      var currentHomeDepotGp = this.computeGp(homeDepotRevenue, usLandedCost, this.feeRates[1])
      var futureHomeDepotGp = this.computeGp(homeDepotRevenue, futureLandedCost.futureLandedCost.US, this.feeRates[1])
      var salesData = p.product.salesData ? JSON.parse(p.product.salesData) : {}
      var homeDepotCanadaRevenueCad = this.computeRevenue(p.product, 6)
      var homeDepotCanadaRevenueUsd = homeDepotCanadaRevenueCad / this.usdToCad
      var currentHomeDepotCanadaGp = this.computeGp(homeDepotCanadaRevenueUsd, onLandedCost, this.feeRates[6])
      var futureHomeDepotCanadaGp = this.computeGp(homeDepotCanadaRevenueUsd, futureLandedCost.futureLandedCost.ON, this.feeRates[6])
      return {
        id: p.product.id,
        itemName: p.product.model,
        nsName: p.product.nsName,
        className: p.product.productClass,
        idIndex: p.product.nsIdIndex,
        usInventory: p.inventory.inventory,
        caInventory: p.inventory.caInventory,
        nyInventory: p.inventory.nyInventory,
        txInventory: p.inventory.txInventory,
        onInventory: p.inventory.onInventory,
        usLandedCost: usLandedCost ? this.roundNum(usLandedCost) : 0,
        caLandedCost: p.inventory.caLandedCost ? this.roundNum(p.inventory.caLandedCost) : 0,
        nyLandedCost: p.inventory.nyLandedCost ? this.roundNum(p.inventory.nyLandedCost) : 0,
        txLandedCost: p.inventory.txLandedCost ? this.roundNum(p.inventory.txLandedCost) : 0,
        onLandedCost: p.inventory.onLandedCost ? this.roundNum(p.inventory.onLandedCost) : 0,
        purchasePrice: p.inventory.purchasePrice ? this.roundNum(p.inventory.purchasePrice) : 0,
        ericPurchasePrice: landedCostParams.ericPurchasePrice,
        futureLandedCost: futureLandedCost,
        wayfairRevenue: wayfairRevenue ? this.roundNum(wayfairRevenue) : '',
        currentWayfairGp: currentWayfairGp ? this.roundNum(currentWayfairGp) : '',
        futureWayfairGp: futureWayfairGp ? this.roundNum(futureWayfairGp) : '',
        wayfairCanadaRevenueCad: wayfairCanadaRevenueCad ? this.roundNum(wayfairCanadaRevenueCad) : '',
        wayfairCanadaRevenueUsd: wayfairCanadaRevenueUsd ? this.roundNum(wayfairCanadaRevenueUsd) : '',
        currentWayfairCanadaGp: currentWayfairCanadaGp ? this.roundNum(currentWayfairCanadaGp) : '',
        futureWayfairCanadaGp: futureWayfairCanadaGp ? this.roundNum(futureWayfairCanadaGp) : '',
        homeDepotRevenue: homeDepotRevenue ? this.roundNum(homeDepotRevenue) : '',
        currentHomeDepotGp: currentHomeDepotGp ? this.roundNum(currentHomeDepotGp) : '',
        futureHomeDepotGp: futureHomeDepotGp ? this.roundNum(futureHomeDepotGp) : '',
        homeDepotCanadaRevenueCad: homeDepotCanadaRevenueCad ? this.roundNum(homeDepotCanadaRevenueCad) : '',
        homeDepotCanadaRevenueUsd: homeDepotCanadaRevenueUsd ? this.roundNum(homeDepotCanadaRevenueUsd) : '',
        currentHomeDepotCanadaGp: currentHomeDepotCanadaGp ? this.roundNum(currentHomeDepotCanadaGp) : '',
        futureHomeDepotCanadaGp: futureHomeDepotCanadaGp ? this.roundNum(futureHomeDepotCanadaGp) : '',
        saleSpeed: salesData.saleSpeedUpdatedAt ? this.roundNum(salesData.saleSpeed) : '',
        saleSpeedCanada: salesData.saleSpeedCanada ? this.roundNum(salesData.saleSpeedCanada) : '',
        saleSpeedDate: salesData.saleSpeedUpdatedAt ? dateFormat(new Date(salesData.saleSpeedUpdatedAt * 1000), 'isoDate') : '',
      }
    },
    computeFeeRates (channels) {
      var feeRates = {}
      for (var c of channels) {
        feeRates[c.id] = c.feeRate
      }
      return feeRates
    },
    computeRevenue (product, channelId) {
      if (channelId == 2) {
        var wayfairData = JSON.parse(product.wayfairData)
        var revenue = 0
        if (wayfairData.wayfairWholesalePrice) {
          revenue = wayfairData.wayfairWholesalePrice.price
        }
        if (revenue && wayfairData.wayfairPromotionPercentage && wayfairData.wayfairPromotionPercentage.percentage) {
          revenue = revenue * (1 - (wayfairData.wayfairPromotionPercentage.percentage / 100))
        }
        return revenue
      } else if (channelId == 7) {
        var wayfairData = JSON.parse(product.wayfairData)
        var revenue = 0
        if (wayfairData.wayfairCanadaWholesalePrice) {
          revenue = wayfairData.wayfairCanadaWholesalePrice.price
        }
        if (revenue && wayfairData.wayfairCanadaPromotionPercentage && wayfairData.wayfairCanadaPromotionPercentage.percentage) {
          revenue = revenue * (1 - (wayfairData.wayfairCanadaPromotionPercentage.percentage / 100))
        }
        return revenue
      } else if (channelId == 1) {
        var homeDepotData = JSON.parse(product.homeDepotData)
        var revenue = 0
        if (homeDepotData.homeDepotWholesalePrice) {
          revenue = homeDepotData.homeDepotWholesalePrice.price
        }
        if (revenue && homeDepotData.homeDepotPromotionPercentage && homeDepotData.homeDepotPromotionPercentage.percentage) {
          revenue = revenue * (1 - (homeDepotData.homeDepotPromotionPercentage.percentage / 100))
        }
        return revenue
      } else if (channelId == 6) {
        var homeDepotData = JSON.parse(product.homeDepotData)
        var revenue = 0
        if (homeDepotData.homeDepotCanadaWholesalePrice) {
          revenue = homeDepotData.homeDepotCanadaWholesalePrice.price
        }
        if (revenue && homeDepotData.homeDepotCanadaPromotionPercentage && homeDepotData.homeDepotCanadaPromotionPercentage.percentage) {
          revenue = revenue * (1 - (homeDepotData.homeDepotCanadaPromotionPercentage.percentage / 100))
        }
        return revenue
      }
    },
    computeGp (revenue, landedCost, feeRate) {
      if (!revenue) {
        return null
      }
      return (revenue * (1 - feeRate) - landedCost) / revenue * 100
    },
    computeFutureLandedCost (p) {
      if (!Object.keys(p).length) {
        return {...p, futureLandedCost: {}}
      }
      var containerCosts = p.containerCosts ? p.containerCosts : {CA: 0, NY: 0, TX: 0, ON: 0}
      containerCosts.ON = containerCosts.ON || 0
      var ericPurchasePrice = p.ericPurchasePrice || 0
      var dutyPercentage = p.dutyPercentage || 0
      var dutyPercentageCanada = p.dutyPercentageCanada || 0
      var duty = this.roundNum(ericPurchasePrice * (dutyPercentage / 100))
      var dutyCanada = this.roundNum(ericPurchasePrice * (dutyPercentageCanada / 100))
      var freightCA = this.roundNum(containerCosts.CA / p.qtyPerContainer)
      var freightNY = this.roundNum(containerCosts.NY / p.qtyPerContainer)
      var freightTX = this.roundNum(containerCosts.TX / p.qtyPerContainer)
      var freightON = this.roundNum(containerCosts.ON / p.qtyPerContainer)
      var futureLandedCostCA = this.roundNum(ericPurchasePrice + duty + freightCA)
      var futureLandedCostNY = this.roundNum(ericPurchasePrice + duty + freightNY)
      var futureLandedCostTX = this.roundNum(ericPurchasePrice + duty + freightTX)
      var futureLandedCostUS = this.roundNum((futureLandedCostCA + futureLandedCostNY + futureLandedCostTX) / 3)
      var futureLandedCostON = this.roundNum(ericPurchasePrice + dutyCanada + freightON)
      var futureLandedCost = {CA: futureLandedCostCA, NY: futureLandedCostNY, TX: futureLandedCostTX, US: futureLandedCostUS, ON: futureLandedCostON}
      return {...p, futureLandedCost: futureLandedCost, computed: true}
    },
    roundNum (n) {
      if (n >= 10) {
        return Math.round(n)
      }
      if (n >= 1) {
        return Math.round(n * 10) / 10
      }
      return Math.round(n * 100) / 100
    },
    makeupProducts (products) {
      var productMap = {}
      products.forEach(p => {
        if (!p.combo) {
          productMap[p.idIndex] = p
        }
      })
      products.forEach(p => {
        if (p.combo) {
          p.caInventory = this.computeComboInventory(p.combo, productMap, 'ca')
          p.nyInventory = this.computeComboInventory(p.combo, productMap, 'ny')
          p.txInventory = this.computeComboInventory(p.combo, productMap, 'tx')
          p.usInventory = p.caInventory + p.nyInventory + p.txInventory
          p.caLandedCost = this.computeComboValue(p.combo, productMap, 'caLandedCost')
          p.nyLandedCost = this.computeComboValue(p.combo, productMap, 'nyLandedCost')
          p.txLandedCost = this.computeComboValue(p.combo, productMap, 'txLandedCost')
          p.onLandedCost = this.computeComboValue(p.combo, productMap, 'onLandedCost')
          var total = p.caInventory * p.caLandedCost + p.nyInventory * p.nyLandedCost + p.txInventory * p.txLandedCost
          p.usLandedCost = p.usInventory ? this.roundNum(total / p.usInventory) : 0
          p.purchasePrice = this.computeComboValue(p.combo, productMap, 'purchasePrice')
          p.futureLandedCost.futureLandedCost = {}
          p.futureLandedCost.futureLandedCost.US = this.computeComboValue(p.combo, productMap, 'futureLandedCost.futureLandedCost.US')
          p.futureLandedCost.futureLandedCost.CA = this.computeComboValue(p.combo, productMap, 'futureLandedCost.futureLandedCost.CA')
          p.futureLandedCost.futureLandedCost.NY = this.computeComboValue(p.combo, productMap, 'futureLandedCost.futureLandedCost.NY')
          p.futureLandedCost.futureLandedCost.TX = this.computeComboValue(p.combo, productMap, 'futureLandedCost.futureLandedCost.TX')
          p.futureLandedCost.futureLandedCost.ON = this.computeComboValue(p.combo, productMap, 'futureLandedCost.futureLandedCost.ON')
          var wayfairRevenue = this.computeRevenue(p.product, 2)
          p.wayfairRevenue = wayfairRevenue ? this.roundNum(wayfairRevenue) : ''
          var currentWayfairGp = this.computeGp(wayfairRevenue, p.usLandedCost, this.feeRates[2])
          var futureWayfairGp = this.computeGp(wayfairRevenue, p.futureLandedCost.futureLandedCost.US, this.feeRates[2])
          p.currentWayfairGp = currentWayfairGp ? this.roundNum(currentWayfairGp) : ''
          p.futureWayfairGp = futureWayfairGp ? this.roundNum(futureWayfairGp) : ''
          var wayfairCanadaRevenueCad = this.computeRevenue(p.product, 7)
          var wayfairCanadaRevenueUsd = wayfairCanadaRevenueCad / this.usdToCad
          p.wayfairCanadaRevenueCad = wayfairCanadaRevenueCad ? this.roundNum(wayfairCanadaRevenueCad) : ''
          p.wayfairCanadaRevenueUsd = wayfairCanadaRevenueUsd ? this.roundNum(wayfairCanadaRevenueUsd) : ''
          var currentWayfairCanadaGp = this.computeGp(wayfairCanadaRevenueUsd, p.onLandedCost, this.feeRates[7])
          var futureWayfairCanadaGp = this.computeGp(wayfairCanadaRevenueUsd, p.futureLandedCost.futureLandedCost.ON, this.feeRates[7])
          p.currentWayfairCanadaGp = currentWayfairCanadaGp ? this.roundNum(currentWayfairCanadaGp) : ''
          p.futureWayfairCanadaGp = futureWayfairCanadaGp ? this.roundNum(futureWayfairCanadaGp) : ''
          var homeDepotRevenue = this.computeRevenue(p.product, 1)
          p.homeDepotRevenue = homeDepotRevenue ? this.roundNum(homeDepotRevenue) : ''
          var currentHomeDepotGp = this.computeGp(homeDepotRevenue, p.usLandedCost, this.feeRates[1])
          var futureHomeDepotGp = this.computeGp(homeDepotRevenue, p.futureLandedCost.futureLandedCost.US, this.feeRates[1])
          p.currentHomeDepotGp = currentHomeDepotGp ? this.roundNum(currentHomeDepotGp) : ''
          p.futureHomeDepotGp = futureHomeDepotGp ? this.roundNum(futureHomeDepotGp) : ''
          var homeDepotCanadaRevenueCad = this.computeRevenue(p.product, 6)
          var homeDepotCanadaRevenueUsd = homeDepotCanadaRevenueCad / this.usdToCad
          p.homeDepotCanadaRevenueCad = homeDepotCanadaRevenueCad ? this.roundNum(homeDepotCanadaRevenueCad) : ''
          p.homeDepotCanadaRevenueUsd = homeDepotCanadaRevenueUsd ? this.roundNum(homeDepotCanadaRevenueUsd) : ''
          var currentHomeDepotCanadaGp = this.computeGp(homeDepotCanadaRevenueUsd, p.onLandedCost, this.feeRates[6])
          var futureHomeDepotCanadaGp = this.computeGp(homeDepotCanadaRevenueUsd, p.futureLandedCost.futureLandedCost.ON, this.feeRates[6])
          p.currentHomeDepotCanadaGp = currentHomeDepotCanadaGp ? this.roundNum(currentHomeDepotCanadaGp) : ''
          p.futureHomeDepotCanadaGp = futureHomeDepotCanadaGp ? this.roundNum(futureHomeDepotCanadaGp) : ''
        }
      })
      return products
    },
    computeComboInventory (combo, productMap, loc) {
      var inventoryMap = {}
      for (var key in combo) {
        var p = productMap[key]
        if (p) {
          var inventory = p[loc + 'Inventory']
          inventoryMap[key] = Math.floor(inventory / combo[key])
        } else {
          inventoryMap[key] = 0
        }
      }
      var inventories = Object.values(inventoryMap)
      return Math.min(...inventories)
    },
    computeComboValue (combo, productMap, field) {
      var value = 0
      for (var key in combo) {
        var p = productMap[key]
        if (p) {
          var fv = p
          for (var f of field.split('.')) {
            fv = fv[f]
          }
          if (fv) {
            value += (fv * combo[key])
          }
        }
      }
      return this.roundNum(value)
    },
    openLandedCostModal (p) {
      this.landedCostParamsModal = {
        opened: true,
        product: p,
      }
    },
    closeLandedCostModal (e) {
      if (e) {
        var productId = this.landedCostParamsModal.product.id
        for (var p of this.products) {
          if (p.id == productId) {
            p.futureLandedCost = this.computeFutureLandedCost(e)
            var futureWayfairGp = this.computeGp(p.wayfairRevenue, p.futureLandedCost.futureLandedCost.US, this.feeRates[2])
            p.futureWayfairGp = futureWayfairGp ? this.roundNum(futureWayfairGp) : ''
            var futureHomeDepotGp = this.computeGp(p.homeDepotRevenue, p.futureLandedCost.futureLandedCost.US, this.feeRates[1])
            p.futureHomeDepotGp = futureHomeDepotGp ? this.roundNum(futureHomeDepotGp) : ''
            var products = this.products.slice()
            this.products = this.makeupProducts(products)
            break
          }
        }
      }
      this.landedCostParamsModal = {
        opened: false,
        product: null,
      }
    },
    closeContainerCostModal (es) {
      if (es) {
        for (var p of this.products) {
          if (es[p.id]) {
            p.futureLandedCost = this.computeFutureLandedCost(es[p.id])
            var futureWayfairGp = this.computeGp(p.wayfairRevenue, p.futureLandedCost.futureLandedCost.US, this.feeRates[2])
            p.futureWayfairGp = futureWayfairGp ? this.roundNum(futureWayfairGp) : ''
            var futureHomeDepotGp = this.computeGp(p.homeDepotRevenue, p.futureLandedCost.futureLandedCost.US, this.feeRates[1])
            p.futureHomeDepotGp = futureHomeDepotGp ? this.roundNum(futureHomeDepotGp) : ''
          }
        }
        var products = this.products.slice()
        this.products = this.makeupProducts(products)
      }
      this.containerCostModal = {
        opened: false,
      }
    },
    exportThisPage () {
      var headers = ['id', 'itemName', 'nsName', 'className', 'idIndex',
        'usInventory', 'caInventory', 'nyInventory', 'txInventory', 'onInventory',
        'saleSpeed', 'saleSpeedCanada', 'saleSpeedDate', 'purchasePrice', 'ericPurchasePrice',
        'usLandedCost', 'caLandedCost', 'nyLandedCost', 'txLandedCost', 'onLandedCost',
        'futureLandedCost.futureLandedCost.US', 'futureLandedCost.futureLandedCost.CA', 'futureLandedCost.futureLandedCost.NY', 'futureLandedCost.futureLandedCost.TX', 'futureLandedCost.futureLandedCost.ON',
        'wayfairRevenue', 'currentWayfairGp', 'futureWayfairGp', 'homeDepotRevenue', 'currentHomeDepotGp', 'futureHomeDepotGp',
        'wayfairCanadaRevenueCad', 'wayfairCanadaRevenueUsd', 'currentWayfairCanadaGp', 'futureWayfairCanadaGp', 'homeDepotCanadaRevenueCad', 'homeDepotCanadaRevenueUsd', 'currentHomeDepotCanadaGp', 'futureHomeDepotCanadaGp',
      ]
      var rows = this.showingProducts.map(p => headers.map (h => (getFieldValue(p, h) ? getFieldValue(p, h) : '')).join('\t'))
      rows.unshift(headers.join('\t'))
      var string = rows.join('\r\n')
      var blob = new Blob([string], {type: 'text/plain;charset=utf-8'})
      var today = dateFormat(new Date(), 'isoDate')
      saveAs(blob, 'landed_costs_' + today + '.tsv')
    },
  },
  mounted () {
    this.getProducts()
  },
}
</script>

<style scoped>
.table-container {
  max-height: calc(100vh - 80px);
  overflow: auto;

  table {
    text-align: left;
    position: relative;
  }

  th {
    background: white;
    position: sticky;
    top: 0;
  }
}
</style>
  